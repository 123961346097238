<template>
  <label
    class="flex items-center v-input sm_text-sm focus-within_ring-2"
    data-testid="v-input-wrapper"
    :class="[{ 'v-input_error': error, 'v-input_disabled': isDisabled }, `v-input_theme-${theme}`]"
  >
    <slot name="icon" :error="error" />
    <component
      :is="component"
      :type="inputType"
      :name="name"
      :placeholder="placeholder"
      :value.prop="modelValue"
      class="w-full px-3 py-2 placeholder-gray-500 bg-transparent border-none outline-none v-input-main-component rounded-md"
      :rows="rows"
      :maxlength="maxlength"
      :disabled="disabled"
      :readonly="readonly"
      @change="$emit('change', $event)"
      @blur="$emit('blur', $event)"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup="$event.key === 'Enter' && $emit('enter', $event.target.value)"
    />
    <button
      v-if="inputType === 'password'"
      class="p-0 mr-1 text-gray-900 bg-transparent appearance-none"
      type="button"
      data-testid="show-password"
      @click="togglePasswordVisibility"
    >
      <EyeIcon class="w-6 h-4" />
    </button>
    <button
      v-else-if="wasPasswordField"
      type="button"
      data-testid="hide-password"
      class="p-0 mr-1 text-gray-900 bg-transparent appearance-none"
      @click="togglePasswordVisibility"
    >
      <EyeSlashIcon class="w-6 h-4" />
    </button>
    <ExclamationCircleIcon
      v-else-if="shouldDisplayExclamationCircle"
      class="w-6 h-4 mr-1 text-red-800 select-none"
      data-testid="error-icon"
    />
    <slot name="button" />
  </label>
</template>

<script lang="ts" setup>
import { EyeIcon, EyeSlashIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  size: {
    type: String as PropType<'sm' | 'base'>,
    default: 'sm',
  },
  name: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  modelValue: {
    type: String as PropType<string | null>,
    default: null,
  },
  error: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: [String, Number] as PropType<string | number | null>,
    default: null,
  },
  maxlength: {
    type: [Number, String],
    default: 500,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  showErrorIcon: {
    type: [Boolean, String],
    default: true,
  },
  theme: {
    type: String,
    default: 'light',
  },
})

defineEmits(['blur', 'change', 'update:modelValue', 'enter'])

const wasPasswordField: Ref<boolean> = ref(false)
const inputType: Ref<string> = ref(props.type)
const shouldDisplayExclamationCircle = computed(() => {
  if (!props.error) return false

  if (!props.showErrorIcon) return false

  return props.type !== 'textarea'
})

function togglePasswordVisibility() {
  const shouldShow = inputType.value === 'password'

  wasPasswordField.value = shouldShow

  inputType.value = shouldShow ? 'text' : 'password'
}

const isDisabled = computed(() => props.disabled || props.readonly)
const component = computed(() => (props.type === 'textarea' ? 'textarea' : 'input'))

watch(
  () => props.type,
  newType => {
    inputType.value = newType
  }
)
</script>

<style lang="stylus" scoped>
input[type="password"]::-ms-reveal
  display none

.v-input
  @apply text-base rounded-md ring-gray-900
  &_theme-light
    @apply bg-white border-gray-300 border shadow-sm text-gray-900
  &_theme-gray
    @apply bg-gray-100 text-gray-900
  &_theme-dark
    @apply bg-gray-900 border border-gray-600 text-gray-100
  &_error
    @apply border border-red-500 text-red-800
  &_disabled
    @apply text-gray-700 truncate
  &-main-component
    line-height 1.15rem

    &:focus
      box-shadow none
</style>
